
import { Dialog, DialogContent, makeStyles, createStyles, Typography, Grid, Paper, Box, CircularProgress, IconButton, Menu, MenuItem } from '@material-ui/core'
import React, { useReducer, useState, useContext, useEffect } from 'react'
import { alpha, useTheme } from "@material-ui/core";
import Maintenance from '../Performance/icons/settings'
import Defects from '../Performance/icons/defects'
import Handling from '../Performance/icons/airplane'
import Departure from '../Performance/icons/depature'
import Records from '../Performance/icons/records'
import Parts from '../Performance/icons/parts'
import Tools from '../Performance/icons/tools'
import MaterialTable from 'material-table';
import tableIcons, { options } from 'components/universal/table_attributes';
import TableAction from 'actions/table_actions'
import tableReducer, {
    INITIAL_TABLE_STATE,
} from 'reducers/table_reducer';
import LandingIcon from '../Performance/icons/plane-landing';
import Servicing from '../Performance/icons/oil';
import { LineMaintainenceServices } from 'lib/services/api/dashboard/line_maintenance';
import _ from 'lodash';
import { formatToMins, formatDateWithHrMin, formatHoursToMins, handleDuration, format24HrDateOnly } from 'lib/utils/common_utils';
import DataService from 'lib/services/api';
import pdfLogo from "../../../../assets/pdf.png";
import jpgLogo from "../../../../assets/jpg.png";
import { ToastMessageContext } from 'lib/contexts/message_context';
import Verified from 'assets/Dashboard/verifiedcopy';
import { ICON_COLOUR } from 'lib/constants/style_constants';
import ResendEmail from 'assets/Dashboard/ResendEmail'
import moment from 'moment';

import { AddBox } from '@material-ui/icons';
import AddMaintTasks from 'views/Operations/LineMaintenance/WorkOrder/AddMaintTasks';
import AddNewPartsAndTools from 'views/Operations/LineMaintenance/WorkOrder/add_parts_tools';
import AddPart from 'views/Dashboard/LineMaintenance/Activity/AddPart';
import MROButton from 'components/buttons';

function DetailsPopUp(props) {
    const { handleClose, selectedRowDetails, data,
        currentSelection
    } = props;
    const classes = useStyles();
    const theme = useTheme()
    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const { page } = tableState;
    const message = useContext(ToastMessageContext)
    const [totalCount, setTotalCount] = useState(10)
    const [loading, setLoading] = useState(false)
    const [popUpData, setPopUpData] = useState({})
    const [records, setRecords] = useState([])
    const [maintTask, setMaintTask] = useState([])
    const [pageSize, setPageSize] = useState(5)
    const [scheduleChecks, setScheduleChecks] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [anchorEl1, setAnchorEl1] = useState(null);
    const [open1, setOpen1] = useState(false);
    const options1 = ["NO Maint Tasks", "With Maint Tasks", "With Maint Tasks & Man-Hrs"];
    const options2 = ["Tech Log Tasks", "Cabin Tasks", "Parts", "Tools"];
    const options3 = ["Tech Log Tasks", "Cabin Tasks", "Tools"];
    const [totalManHours, setTotalManHours] = useState(0)
    const ITEM_HEIGHT = 48;
    // console.log("Popuppp", selectedRowDetails)




    //parts page 
    const [parts, setParts] = useState([])
    const [totalCount1, setTotalCount1] = useState(0)
    const [pageSize1, setPageSize1] = useState(5)
    const [page1, setPage1] = useState(0)



    //tools

    const [tools, setTools] = useState([])
    const [totalCount2, setTotalCount2] = useState(0)
    const [pageSize2, setPageSize2] = useState(5)
    const [page2, setPage2] = useState(0)

    const [action, setAction] = useState(null)
    const [currentSelections, setCurrentSelections] = useState({ child_section_name: "" })

    const [createdId, setCreatedId] = useState("");



    function container() {
        // Use the fullscreen element if in fullscreen mode, otherwise just the document's body
        return document.fullscreenElement ?? document.body;
    }
    //api call for popup details

    const getDetails = async () => {
        setLoading(true)
        const params = {
            work_order_id: currentSelection?.work_order_id,
            page: page + 1,
            limit: pageSize
        }
        await LineMaintainenceServices.getPopUpDetails(params).then((response) => {
            setPopUpData(response.data.work_order_details)
            setRecords(response.data.records)
            setMaintTask(response.data.maint_tasks.data)
            setTotalManHours(response.data.total_hours)
            setScheduleChecks(response.data.schedule_checks)
            setTotalCount(response.data.maint_tasks.total_count)
        }).catch((err) => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        getDetails()
    }, [page, pageSize, totalCount])

    const handleUpdate = (newData) => {
        let lae = newData.lae_hrs ? +newData.lae_hrs : 0
        let non_lae = newData.non_lae_hrs ? +newData.non_lae_hrs : 0
        let manHours = parseFloat(lae) + parseFloat(non_lae)
        let params = {
            id: newData.id,
            description: newData.description,
            lae_hrs: lae,
            non_lae_hrs: non_lae,
            man_hours: manHours
        }
        DataService.updateDataWithParams(params, 'tasks').then(res => {
            console.log(res)
            getDetails()
        }).catch(err => {
            message.showToastMessage({ message: 'Something went wrong', variant: 'error' })
        })
    }
    //send mail
    const sendMail = (nomain, withmain, manhrs) => {
        let params = {
            id: currentSelection?.work_order_id,
            no_maint_tasks: nomain,
            with_maint_tasks: withmain,
            man_hrs: manhrs,
        };
        DataService.getData(params, "workOrderSendEmail")
            .then((res) => {
                message.showToastMessage({
                    message: "Email Sent Successfully!",
                    variant: "success",
                });
                setOpen(false)
            })
            .catch((err) => {
                message.showToastMessage({
                    message: "Unable to send email",
                    variant: "error",
                });
            });
    };

    const handleClick = (event) => {
        setOpen(true);
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setOpen(false);
        // setAction(null);
        // setCurrentSelection({});
    };
    const handleOption = (option) => {
        console.log("options======", option)
        if (option === "NO Maint Tasks") {
            sendMail(true, false, false)
        }
        if (option === "With Maint Tasks") {
            sendMail(false, true, false)
        }
        if (option === "With Maint Tasks & Man-Hrs") {
            sendMail(false, false, true)
        }
    }

    // VerifyState
    const user = JSON.parse(sessionStorage.getItem("user"));
    const VerifyState = () => {
        let params = {
            work_order_id: currentSelection?.work_order_id,
            verified: popUpData.verified === true ? 'false' : 'true',
            verify_user_id: popUpData.verified === false ? user?.id : popUpData.verified === null ? user?.id : null,
            verify_datetime: popUpData.verified === false ? moment().format("YYYY-MM-DD hh:mm:ss") : popUpData.verified === null ? moment().format("YYYY-MM-DD hh:mm:ss") : null,
        }
        LineMaintainenceServices.updateVerifyState(params).then((res) => {
            getDetails()
            message.showToastMessage({
                message: "Verified Successfully!",
                variant: "success",
            });
        }).catch(() => {
            message.showToastMessage({
                message: "Something Went Wrong",
                variant: "error",
            });
        }).finally(() => {

        })
    }

    const columns = [
        {
            title: 'Task / TLP',
            field: 'task_tlp',
            editable: 'never',
            headerStyle: {
                textAlign: 'center',
                // paddingLeft: '5px'
            },
            cellStyle: {
                textAlign: 'center',
                // paddingLeft: '5px'
            },
            render: rowData => (
                <Typography style={{ color: rowData?.pt_status === 2 ? "red" : "" }}>{rowData?.task_tlp}</Typography>
            )
        },
        {
            title: 'Type',
            field: "task_type",
            editable: 'never',
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left',
            },

        },
        {
            title: 'Maintenance Task',
            field: 'description',
            headerStyle: {
                textAlign: 'left',
                width: '40%'
            },
            cellStyle: {
                textAlign: 'left',
                width: '40%',
                paddingLeft: '7px'
            },
            width: '40%'
        },
        {
            title: 'LAE Hrs',
            field: 'lae_hrs',

            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left',
                paddingLeft: '20px'
            },
        },
        {
            title: 'Non LAE Hrs',
            field: 'non_lae_hrs',

            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left',
                paddingLeft: '26px'
            },
        },

    ]

    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        pageSizeOptions: [5, 10, 20],
        search: false,
        sort: false,
        headerStyle: {

            color: theme.palette.primary.main
        }

    };


    //New items parts and tools

    const handleAdd = (event) => {
        setOpen1(true);
        setAnchorEl1(event.currentTarget);
    };

    const handleCloseMenu1 = () => {
        setOpen1(false);
        // setAction(null);
        // setCurrentSelection({});
    };
    const handlePartsOptions = (option) => {
        setOpen1(false)
        if (option === "Tech Log Tasks") {
            setAction("techlog")
            setCurrentSelections({ child_section_name: "Tech Log Maint Tasks" })
        }
        if (option === "Cabin Tasks") {
            setAction("cabintask")
            setCurrentSelections({ child_section_name: "Cabin Maint Tasks" })
        }
        if (option === "Parts") {
            setAction("parts")
        }
        if (option === "Tools") {
            setAction("tools")
        }
    }

    const tableOptions1 = {
        ...options,
        page: page1,
        search: false,
        total: totalCount1,
        pageSize: pageSize1,
        search: false,
        toolbar: false,
    };
    const tableOptions2 = {
        ...options,
        page: page2,
        search: false,
        total: totalCount2,
        pageSize: pageSize2,
        search: false,
        toolbar: false,
    };

    const getList = async () => {
        const params = {
            reportable_type: "WorkOrder",
            reportable_id: currentSelection?.work_order_id,
            page: 1 + page1,
            count_per_page: pageSize1,
            to_issued: true,
        };

        await LineMaintainenceServices.getPartsDetails(params).then((res) => {
            setParts(res.data)
            setTotalCount1(res.total_count)
        }).catch((err) => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })
    };

    useEffect(() => {
        getList();
    }, [page1, pageSize1]);



    const column1 = [
        {
            title: "",
            field: "id",
            width: "4%",
            render: (rowData) => <span>&nbsp;</span>,
        },

        {
            title: "Part Number",
            field: "part_number",
            headerStyle: {
                textAlign: "left",
            },
            cellStyle: {
                textAlign: "left",
                width: "20%",
            },
        },
        {
            title: "Description",
            field: "description",
            headerStyle: {
                textAlign: "left",
            },
            cellStyle: {
                textAlign: "left",
                width: "46%",
            },
        },
        {
            title: "Ordered",
            field: "required_quantity",
            headerStyle: {
                textAlign: "center",
            },
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: "Issued",
            field: "issued_quantity",
            headerStyle: {
                textAlign: "center",
            },
        },
        {
            title: "Remaining",
            field: "remaining_quantity",
            headerStyle: {
                textAlign: "center",
            },
        },

    ];


    //tools

    const getToolingDetails = async () => {
        const params = {
            reportable_type: "WorkOrder",
            reportable_id: currentSelection?.work_order_id,
            page: 1 + page2,
            count_per_page: pageSize2,

        };

        await LineMaintainenceServices.getPartsTools(params).then((res) => {
            setTools(res.data)
            setTotalCount2(res.total_count)
        }).catch((err) => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })
    };

    useEffect(() => {
        getToolingDetails();
    }, [page2, pageSize2]);


    const column2 = [

        {
            title: 'Tool Number',
            field: 'tool_number',
            headerStyle: {
                textAlign: 'left',
                paddingLeft: "10px"
            },
            cellStyle: {
                textAlign: 'left',
                paddingLeft: "10px"
                // width: '0.8%'
            }
        },
        {
            title: 'Description',
            field: 'description',
            headerStyle: {
                textAlign: 'left',
                width: "40%"
            },
            cellStyle: {
                textAlign: 'left',
                width: "40%"
                // width: '0.8%'
            }
        },
        {
            title: 'Calibration Date',
            field: 'calibration_due',
            render: rowData => format24HrDateOnly(rowData?.calibration_due),
            headerStyle: {
                textAlign: 'center',
            },
            cellStyle: {
                textAlign: 'center',
                // width: '0.8%'
            }
        },

    ]




    return (
        <div>
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="otp"
                aria-describedby="otp"
                className={classes.modal}
                container={container()}
            >
                <DialogContent>

                    {loading && _.isEmpty(popUpData) ? (
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            minHeight="700px"
                        >
                            <CircularProgress color="primary" size={40} />
                        </Box>
                    ) :
                        < Grid >
                            <Grid spacing={1}>
                                <Paper>
                                    <Grid xs={12} container style={{ height: '55px' }} >
                                        <Grid xs={2} className={`${classes.org1} ${classes.gtcdc}`}>
                                            <Typography style={{ fontSize: '15px', fontWeight: " bold" }}>{popUpData.aircraft_registration}</Typography>
                                            <Typography className={classes.textPrimary}>{popUpData.iata_code}</Typography>
                                        </Grid>
                                        <Grid xs={2} className={classes.firstColumn}>
                                            <Typography className={classes.headingText}>{popUpData.work_order_number ? popUpData.work_order_number : '-'}</Typography>
                                            <Typography className={classes.orderText}>Order #</Typography>
                                        </Grid>
                                        <Grid xs={2}>
                                            <Typography className={classes.headingText}>{popUpData.tlp ? popUpData.tlp : '-'}</Typography>
                                            <Typography className={classes.orderText}>TLP</Typography>
                                        </Grid>
                                        <Grid xs={2}>
                                            <Typography className={classes.headingText}>{popUpData.station ? popUpData.station : '-'}</Typography>
                                            <Typography className={classes.orderText}>Base</Typography>
                                        </Grid>
                                        <Grid xs={2}>
                                            <Typography className={classes.headingText}>{popUpData.stamp_number ? popUpData.stamp_number : '-'}</Typography>
                                            <Typography className={classes.orderText}>User</Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <br />
                            {/*Arrival Grid */}
                            <Grid>
                                <Paper>
                                    <Grid xs={12} container style={{ height: '55px' }} >
                                        <Grid xs={2} className={`${classes.org1} ${classes.leftContent}`}>
                                            {/* <img src={Landing} height="18px" style={{ fill: 'red' }} /> */}

                                            <LandingIcon fill={theme.palette.primary.main} />
                                            <Typography className={classes.textPrimary}>Arrival</Typography>
                                        </Grid>
                                        <Grid xs={1} className={classes.firstColumn}>
                                            <Typography className={classes.headingText} >{popUpData.arr_flight ? popUpData.arr_flight : '-'}</Typography>
                                            <Typography className={classes.orderText}>Flight</Typography>
                                        </Grid>
                                        <Grid xs={2} className={classes.firstColumn}>
                                            <Typography className={classes.headingText} >{popUpData.est_arr_date ? formatDateWithHrMin(popUpData.est_arr_date) : '-'}</Typography>
                                            <Typography className={classes.orderText}>Estimated</Typography>
                                        </Grid>
                                        <Grid xs={2}>
                                            <Typography className={classes.headingText}>{popUpData.aircraft_arrival_date ? formatDateWithHrMin(popUpData.aircraft_arrival_date) : '-'}</Typography>
                                            <Typography className={classes.orderText}>Actual</Typography>
                                        </Grid>
                                        <Grid xs={2}>
                                            <Typography className={classes.headingText} style={{ color: handleDuration(popUpData.delay_in) }}>{popUpData.delay_in ? formatHoursToMins(popUpData.delay_in) : "-"}</Typography>
                                            <Typography className={classes.orderText}>Delay</Typography>
                                        </Grid>
                                    </Grid>


                                </Paper>
                            </Grid>

                            <br />
                            {/**Servicing */}


                            <Grid>
                                <Paper>
                                    <Grid xs={12} container style={{ height: '55px' }} >
                                        <Grid xs={2} className={`${classes.org1} ${classes.leftContent}`}>
                                            <Servicing fill={theme.palette.primary.main} />
                                            <Typography className={classes.textPrimary} >Servicing</Typography>

                                        </Grid>

                                        <Grid xs={2} className={classes.firstColumn}>
                                            <Typography className={classes.headingText}>{popUpData.oil_quarts ? popUpData.oil_quarts : '-'}</Typography>
                                            <Typography className={classes.orderText}>Oil</Typography>
                                        </Grid>
                                        <Grid xs={2}>
                                            <Typography className={classes.headingText}>{popUpData.hyd_quarts ? popUpData.hyd_quarts : '-'}</Typography>
                                            <Typography className={classes.orderText}>Hyds</Typography>
                                        </Grid>
                                        <Grid xs={2}>
                                            <Typography className={classes.headingText}>{popUpData.nitrogen_value ? popUpData.nitrogen_value : '-'}</Typography>
                                            <Typography className={classes.orderText}>N/O2 Top Ups</Typography>
                                        </Grid>

                                        <Grid xs={2}>
                                            <Typography className={classes.headingText}>{popUpData.changed_wheels ? popUpData.changed_wheels : '-'}</Typography>
                                            <Typography className={classes.orderText}>N/Whl Change</Typography>
                                        </Grid>
                                        {/* <Grid xs={1}>
                                        <Typography className={classes.headingText}>0</Typography>
                                        <Typography className={classes.orderText}> Fuel</Typography>
                                    </Grid> */}
                                    </Grid>


                                </Paper>
                            </Grid>
                            <br />
                            {/**Maintenance */}

                            <Grid>
                                <Paper>
                                    <Grid xs={12} container  >
                                        <Grid xs={2} className={`${classes.org1} ${classes.maintenanceSettings}`}>
                                            <Maintenance fill={theme.palette.primary.main} />
                                            <Typography className={classes.textPrimary}>Maintenance</Typography>
                                        </Grid>
                                        <Grid xs={10} container direction='column'>
                                            <Grid className={classes.firstColumn} style={{ paddingBottom: '10px' }}>
                                                {scheduleChecks.length !== 0 && scheduleChecks !== null || undefined ? scheduleChecks.map((item, index) => (<span className={classes.headingText}>{item}{index < scheduleChecks.length - 1 ? ", " : ""}</span>)) :
                                                    <span className={classes.headingText}>-</span>}
                                                <Typography className={classes.orderText}>Scheduled Checks</Typography></Grid>


                                            <Grid style={{ padding: '10px 20px 20px 16px' }} >

                                                <MaterialTable
                                                    style={{ boxShadow: '0px 1px 3px #00000033', paddingRight: '0px' }}
                                                    icons={tableIcons}
                                                    title={<Grid >
                                                        <Typography className={classes.textPrimary1}>Maintenance Tasks</Typography>
                                                        <Typography className={classes.textPrimary1} style={{
                                                            position: "absolute",
                                                            /* margin-right: 27px; */
                                                            right: "22px",
                                                            top: " 22px"
                                                        }}>Total Hours: <span style={{ paddingLeft: "26px", color: "#000", fontSize: "15px" }}>
                                                                {totalManHours ? totalManHours : 0}
                                                            </span> </Typography>


                                                    </Grid>}
                                                    options={tableOptions}
                                                    data={maintTask || []}
                                                    columns={columns}
                                                    isLoading={loading}
                                                    editable={(props?.permission?.read || !props?.permission?.read) && !props?.permission?.write ? "" : props?.permission?.write &&
                                                    {
                                                        onRowUpdate: (newData, oldData) =>
                                                            new Promise((resolve, reject) => {
                                                                handleUpdate(newData, oldData)
                                                                resolve();
                                                            }),
                                                    }
                                                    }


                                                    onChangePage={(page) => {
                                                        TableAction.setPage(dispatch, page);
                                                    }}
                                                    onChangeRowsPerPage={(pageSize) => {
                                                        setPageSize(pageSize);
                                                    }}
                                                    page={page}
                                                    totalCount={totalCount}
                                                />

                                            </Grid>
                                            <Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>


                                </Paper>
                            </Grid>
                            <br />
                            <Grid>
                                <Paper>
                                    <Grid xs={12} container  >
                                        <Grid xs={2} className={`${classes.org1} ${classes.maintenanceSettings}`}>
                                            <Parts fill={theme.palette.primary.main} />
                                            <Typography className={classes.textPrimary}>Parts</Typography>
                                        </Grid>
                                        <Grid xs={10} container direction='column'>

                                            <Grid style={{ padding: '10px 20px 20px 16px' }} >

                                                <MaterialTable
                                                    style={{ boxShadow: '0px 1px 3px #00000033', paddingRight: '0px' }}
                                                    icons={tableIcons}
                                                    title={""}
                                                    options={tableOptions1}
                                                    data={parts || []}
                                                    columns={column1}
                                                    isLoading={loading}

                                                    onChangePage={(page) => {
                                                        setPage1(page);
                                                    }}
                                                    onChangeRowsPerPage={(pageSize) => {
                                                        setPageSize1(pageSize);
                                                    }}
                                                    page={page1}
                                                    totalCount={totalCount1}
                                                />

                                            </Grid>
                                            <Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>


                                </Paper>
                            </Grid>

                            <br />


                            {/* Tools*/}

                            <Grid>
                                <Paper>
                                    <Grid xs={12} container  >
                                        <Grid xs={2} className={`${classes.org1} ${classes.maintenanceSettings}`}>
                                            <Tools fill={theme.palette.primary.main} />
                                            <Typography className={classes.textPrimary}>Tools</Typography>
                                        </Grid>
                                        <Grid xs={10} container direction='column'>

                                            <Grid style={{ padding: '10px 20px 20px 16px' }} >

                                                <MaterialTable
                                                    style={{ boxShadow: '0px 1px 3px #00000033', paddingRight: '0px' }}
                                                    icons={tableIcons}
                                                    title={""}
                                                    options={tableOptions2}
                                                    data={tools || []}
                                                    columns={column2}
                                                    isLoading={loading}

                                                    onChangePage={(page) => {
                                                        setPage2(page);
                                                    }}
                                                    onChangeRowsPerPage={(pageSize) => {
                                                        setPageSize2(pageSize);
                                                    }}
                                                    page={page2}
                                                    totalCount={totalCount2}
                                                />

                                            </Grid>
                                            <Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>


                                </Paper>
                            </Grid>

                            <br />
                            <br />
                            {/**D/Defects */}


                            {/* <Grid>
                            <Paper>
                                <Grid xs={12} container style={{ height: '55px' }} >
                                    <Grid xs={2} className={`${classes.org1} ${classes.leftContent}`}>
                                        <Defects fill={theme.palette.primary.main} />
                                        <Typography className={classes.textPrimary}>D/Defects</Typography>
                                    </Grid>
                                    <Grid xs={2} className={classes.firstColumn}>
                                        <Typography className={classes.headingText}>6</Typography>
                                        <Typography className={classes.orderText}>In</Typography>
                                    </Grid>
                                    <Grid xs={2}>
                                        <Typography className={classes.headingText}>1</Typography>
                                        <Typography className={classes.orderText}>Raised</Typography>
                                    </Grid>
                                    <Grid xs={2}>
                                        <Typography className={classes.headingText}>3</Typography>
                                        <Typography className={classes.orderText}>Cleared</Typography>
                                    </Grid>
                                    <Grid xs={2}>
                                        <Typography className={classes.headingText}>4</Typography>
                                        <Typography className={classes.orderText}>Out</Typography>
                                    </Grid>

                                </Grid>


                            </Paper>
                        </Grid>
                        <br /> */}
                            {/**Handling */}


                            <Grid>
                                <Paper>
                                    <Grid xs={12} container style={{ height: '55px' }} >
                                        <Grid xs={2} className={`${classes.org1} ${classes.leftContent}`}>
                                            <Handling fill={theme.palette.primary.main} />
                                            <Typography className={classes.textPrimary}>Handling</Typography>
                                        </Grid>
                                        <Grid xs={1} className={classes.firstColumn}>
                                            <Typography className={classes.headingText}>{popUpData.towing_man_hours ? popUpData.towing_man_hours : '-'}</Typography>
                                            <Typography className={classes.orderText}>Tows</Typography>
                                        </Grid>
                                        <Grid xs={2} style={{ paddingLeft: '15px' }}>
                                            <Typography className={classes.headingText}>{popUpData.large_steps === "1" ? "Yes" : "No"}</Typography>
                                            <Typography className={classes.orderText}>Large Steps</Typography>
                                        </Grid>
                                        <Grid xs={2}>
                                            <Typography className={classes.headingText}>{popUpData.medium_steps === "1" ? "Yes" : "No"}</Typography>
                                            <Typography className={classes.orderText}>Medium Steps</Typography>
                                        </Grid>

                                        <Grid xs={2}>
                                            <Typography className={classes.headingText}>{popUpData.gnd_equip_value === "1" ? "Yes" : "No"}</Typography>
                                            <Typography className={classes.orderText}>APU Access Stand</Typography>
                                        </Grid>
                                        <Grid xs={1} >
                                            <Typography className={classes.headingText}>{popUpData.high_lift_equip_value ? popUpData.high_lift_equip_value : '-'}</Typography>
                                            <Typography className={classes.orderText}>High Lift (Hrs)</Typography>
                                        </Grid>
                                        <Grid xs={2} style={{ paddingLeft: '17px' }}>
                                            <Typography className={classes.headingText}>{popUpData.wheel_change_kit ? popUpData.wheel_change_kit : '-'}</Typography>
                                            <Typography className={classes.orderText}>Wheel Change Kit</Typography>
                                        </Grid>
                                    </Grid>



                                </Paper>
                            </Grid>
                            <br />
                            {/**Departure */}
                            <Grid>
                                <Paper>
                                    <Grid xs={12} container style={{ height: '55px' }} >
                                        <Grid xs={2} className={`${classes.org1} ${classes.leftContent}`}>
                                            <Departure fill={theme.palette.primary.main} />
                                            <Typography className={classes.textPrimary}>Departure</Typography>
                                        </Grid>
                                        <Grid xs={1} className={classes.firstColumn}>
                                            <Typography className={classes.headingText}>{popUpData.dep_flight ? popUpData.dep_flight : '-'}</Typography>
                                            <Typography className={classes.orderText}>Flight</Typography>
                                        </Grid>
                                        <Grid xs={2} className={classes.firstColumn}>
                                            <Typography className={classes.headingText}>{popUpData.est_dep_date ? formatDateWithHrMin(popUpData.est_dep_date) : '-'}</Typography>
                                            <Typography className={classes.orderText}>Estimated</Typography>
                                        </Grid>
                                        <Grid xs={2}>
                                            <Typography className={classes.headingText}>{popUpData.act_dep_date ? formatDateWithHrMin(popUpData.act_dep_date) : '-'}</Typography>
                                            <Typography className={classes.orderText}>Actual</Typography>
                                        </Grid>
                                        <Grid xs={2}>
                                            <Typography className={classes.headingText}>{popUpData.gnd_time ? formatToMins(popUpData.gnd_time) : "-"}</Typography>
                                            <Typography className={classes.orderText}>Gnd Time</Typography>
                                        </Grid>
                                        <Grid xs={1}>
                                            <Typography className={classes.headingText} style={{ color: handleDuration(popUpData.delay_time) }}>{popUpData.delay_time ? formatHoursToMins(popUpData.delay_time) : "-"}</Typography>
                                            <Typography className={classes.orderText}>Delay</Typography>
                                        </Grid>
                                        <Grid xs={2}>
                                            <Typography className={classes.headingText}>{popUpData.delay_code ? popUpData.delay_code : "-"}</Typography>
                                            <Typography className={classes.orderText}>Code</Typography>
                                        </Grid>
                                    </Grid>


                                </Paper>
                            </Grid>
                            <br />
                            {/**Records */}

                            <Grid>
                                <Paper style={{ height: 'max-content' }}>
                                    <Grid xs={12} container  >
                                        <Grid xs={2} className={`${classes.org1} ${classes.leftContent} ${classes.maintenanceSettings}`}>
                                            <Records fill={theme.palette.primary.main} />
                                            <Typography className={classes.textPrimary}>Records</Typography>
                                        </Grid>
                                        <Grid xs={10}>
                                            {records.length === 0 ? <Typography variant="subtitle2" className={classes.maintenanceSettings} style={{ paddingLeft: '10px' }}>No files found</Typography> : records.map((items, index) => {

                                                const fileType = items.file_name.split(".")[1];

                                                let logo = pdfLogo;
                                                if (fileType === "jpg") {
                                                    logo = jpgLogo;
                                                }
                                                return (
                                                    <a
                                                        href={`${items.path}`}
                                                        target="_blank"
                                                        style={{ color: "inherit", textDecoration: "none" }}
                                                    >
                                                        <div key={index} className={classes.filePreviewContainer} >
                                                            <img src={logo} alt="file-logo" className={classes.logo} />
                                                            <Typography
                                                                variant="body1"
                                                                noWrap
                                                                style={{ marginTop: "10px", color: "#4D4F5C" }}
                                                            >
                                                                {items.file_name}
                                                            </Typography>
                                                        </div>
                                                    </a>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>


                                </Paper>
                            </Grid>
                            <br />


                            <Grid>
                                <Paper>
                                    <Grid xs={12} container style={{ height: '55px' }} >
                                        <Grid xs={2} className={`${classes.org1} ${classes.leftContent}`}>
                                            <Verified fill={popUpData.verified === true ? "#4caf50" : popUpData.verified === false ? '#FFCD5A' : "#A9A9A9"} />
                                            <Typography className={classes.textPrimary}>Verification</Typography>
                                        </Grid>
                                        <Grid xs={2} className={classes.firstColumn}>
                                            <Typography className={classes.headingText}>{popUpData.verified === false ? "NOT verified" : popUpData.verified === true ? "Verified" : "-"}</Typography>
                                            <Typography className={classes.orderText}>State</Typography>
                                        </Grid>
                                        <Grid xs={2}>
                                            <Typography className={classes.headingText}>{popUpData.verify_user_stamp ? popUpData.verify_user_stamp : '-'}</Typography>
                                            <Typography className={classes.orderText}>User</Typography>
                                        </Grid>
                                        <Grid xs={3}>
                                            <Typography className={classes.headingText}>{popUpData.verify_datetime ? formatDateWithHrMin(popUpData.verify_datetime) : "-"}</Typography>
                                            <Typography className={classes.orderText}>Verified</Typography>
                                        </Grid>
                                        {(props?.permission?.read || !props?.permission?.read) && !props?.permission?.write ? "" : props?.permission?.write && <>            <Grid xs={1}>
                                            <IconButton style={{ marginTop: '15px', paddingLeft: '19px' }} onClick={(e) => {
                                                handleAdd(e);

                                            }}>     <AddBox htmlColor={"#4D4F5C"} style={{ fontSize: "26px" }} /></IconButton>

                                        </Grid>
                                            <Grid xs={1}>
                                                <IconButton style={{ marginTop: '17px' }} onClick={(event) => {
                                                    event.stopPropagation();
                                                    VerifyState();
                                                }}> <Verified fill={"#4D4F5C"}
                                                    /></IconButton>

                                            </Grid>
                                            <Grid xs={1}>
                                                <IconButton style={{ marginTop: '19px', paddingLeft: '2px' }} onClick={(e) => {
                                                    handleClick(e);

                                                }}>     <ResendEmail fill={"#4D4F5C"}

                                                    /></IconButton>
                                                <Typography className={classes.orderText} style={{ marginTop: '-5px' }}>Resend Email</Typography>
                                            </Grid>
                                        </>}
                                    </Grid>

                                </Paper>

                            </Grid>
                            <br />
                            <br />
                            {open && <Menu
                                id={`long-menu`}
                                anchorEl={anchorEl}
                                keepMounted
                                open={open}
                                onClose={handleCloseMenu}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                                transformOrigin={{ horizontal: "left" }}
                                PaperProps={{
                                    style: {
                                        maxHeight: ITEM_HEIGHT * 5.5,
                                        width: "24ch",

                                    },
                                }}
                                style={{
                                    marginTop: '-101px',
                                    marginLeft: '-151px'

                                    // top: '135px',
                                    // left: '944px'
                                }}
                            >
                                <Typography color='primary' style={{ fontSize: '16px', paddingLeft: '14px' }}> Send Options</Typography>
                                {options1.map((option) => {

                                    return (
                                        <MenuItem
                                            // disabled={!props.permission.write && option !== "U/S label"}
                                            key={option}
                                            style={{ fontSize: "12px" }}
                                            onClick={() => {
                                                handleOption(option);
                                            }}
                                        >
                                            {option}
                                        </MenuItem>
                                    );
                                })}



                            </Menu>}
                            {open1 && <Menu
                                id={`long-menu`}
                                anchorEl={anchorEl1}
                                keepMounted
                                open={open1}
                                onClose={handleCloseMenu1}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                                transformOrigin={{ horizontal: "left" }}
                                PaperProps={{
                                    style: {
                                        maxHeight: ITEM_HEIGHT * 5.5,
                                        width: "24ch",

                                    },
                                }}
                                style={{
                                    marginTop: '-101px',
                                    marginLeft: '-151px'

                                    // top: '135px',
                                    // left: '944px'
                                }}
                            >
                                <Typography color='primary' style={{ fontSize: '16px', paddingLeft: '14px' }}> Add Options</Typography>
                                {(popUpData?.part_type_name !== "non_managed_part" ? options2 : options3).map((option) => {

                                    return (
                                        <MenuItem
                                            // disabled={!props.permission.write && option !== "U/S label"}
                                            key={option}
                                            style={{ fontSize: "12px" }}
                                            onClick={() => {
                                                handlePartsOptions(option);
                                            }}
                                        >
                                            {option}
                                        </MenuItem>
                                    );
                                })}



                            </Menu>}
                        </Grid>}

                </DialogContent>
                {action && (action === "techlog" || action === "cabintask") && <AddMaintTasks
                    currentSelection={currentSelections}
                    setCreatedId={setCreatedId}
                    workOrderId={currentSelection?.work_order_id}
                    handleClose={() => {
                        setAction(null)
                        getDetails()
                    }}
                    lineCustomerId={popUpData?.line_customer_id}
                />}
                {action && action === "tools" &&

                    <AddNewPartsAndTools
                        createSection={"tool"}
                        setCreatedId={setCreatedId}
                        handleClose={() => {
                            setAction(null)
                            getToolingDetails()
                        }}
                        workOrderId={currentSelection?.work_order_id}
                        lineCustomerId={popUpData?.line_customer_id}
                    />}
                {action && action === "parts" &&

                    <AddPart
                        createSection={"part"}
                        setCreatedId={setCreatedId}
                        handleClose={() => {
                            setAction(null)
                            getList()
                        }}
                        workOrderId={currentSelection?.work_order_id}
                        lineCustomerId={popUpData?.line_customer_id}
                        popUpData={popUpData}
                    />}

            </Dialog >
        </div >
    )
}

export default DetailsPopUp


const useStyles = makeStyles((theme) =>
    createStyles({
        modal: {
            "& .MuiDialog-paperWidthSm": {
                minWidth: "900px",
                // height: "110vh"
            },
        },

        bodyText: {
            fontSize: 20,
            lineHeight: "28px",
            textAlign: 'center',
            marginTop: '25px'
        },

        org1: {
            background: alpha(theme.palette.primary.main, 0.20),

        },
        gtcdc: {
            borderRight: '1px solid transparent',
            textAlign: 'center',
            fontWeight: 600,
            color: theme.palette.primary.main
        },
        leftContent: {
            textAlign: 'center', paddingTop: '7px'
        },
        firstColumn: {

            paddingLeft: '17px',

        },

        headingText: {
            fontSize: '12px',
            paddingTop: '10px'
        },

        orderText: {
            fontSize: '10px',
            color: theme.palette.primary.main

        },
        maintenanceSettings: {
            display: ' flex',
            justifyContent: 'center',
            flexDirection: "column",

            alignItems: 'center'
        },
        textPrimary: {
            color: theme.palette.primary.main,
            fontSize: '15px'
        },
        textPrimary1: {
            color: theme.palette.primary.main,
            fontSize: '14px'
        },
        filePreviewContainer: {
            display: 'inline-block',
            maxWidth: "100px",
            marginRight: "30px",
            paddingTop: '10px',
            paddingLeft: '10px'
        },

        logo: {
            height: "50px",
        },

    })
);
